import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="chat"
export default class extends Controller {
    static targets = ["prompt", "conversation"]
    static values = { model: String }

    disconnect() {
        if (this.eventSource) {
            this.eventSource.close()
        }
    }

    generateResponse(event) {
        event.preventDefault()

        this.#createLabel('You')
        this.#createMessage(this.promptTarget.value)
        if (this.modelValue === "chatgpt") {
          this.#createLabel('ChatGPT')
        } else if (this.modelValue === "grok") {
          this.#createLabel('Grok')
        }
        this.currentPre = this.#createMessage()

        this.#setupEventSource()

        this.promptTarget.value = ""
    }

    #createLabel(text) {
        const label = document.createElement('p');
        label.innerHTML = `${text}:`;
        this.conversationTarget.appendChild(label);
    }

    #createMessage(text = '') {
        const preElement = document.createElement('pre');
        preElement.innerHTML = text;
        this.conversationTarget.appendChild(preElement);
        return preElement
    }

    #setupEventSource() {
        if (this.modelValue === "chatgpt") {
          this.eventSource = new EventSource(`/chat_responses?prompt=${this.promptTarget.value}`)
        } else if (this.modelValue === "grok") {
          this.eventSource = new EventSource(`/grok_chat_responses?prompt=${this.promptTarget.value}`)
        }
        this.eventSource.addEventListener("message", this.#handleMessage.bind(this))
        this.eventSource.addEventListener("error", this.#handleError.bind(this))
    }

    #handleMessage(event) {
        const parsedData = JSON.parse(event.data);
        this.currentPre.innerHTML += parsedData.message;

        // Scroll to bottom of conversation
        this.conversationTarget.scrollTop = this.conversationTarget.scrollHeight;
    }

    #handleError(event) {
        if (event.eventPhase === EventSource.CLOSED) {
            this.eventSource.close()
        }
    }
}



// import { Controller } from "stimulus"
//
//
// export default class extends Controller {
//   statis targets = ["prompt", "conversation"]
//
//
//
//   generateResponse(event) {
//      console.log("David")
//     event.preventDefault()
//     this.#createLabel("You")
//     this.#createMessage(this.promptTarget.value)
//     this.#createLabel("ChatGPT")
//     this.currentPre = this.#createMessage()
//
//     this.#setupEventSource()
//
//     this.promptTarget.value = ""
//   }
//
//   #createLabel {
//     const label = document.createElement( tagName: "strong");
//     label.innerHTML = `${text}`;
//     this.conversationTarget.appendChild(label);
//   }
//
//   #createMessage {
//     const preElement = document.createElement( tagName: "pre")
//     preElement.innerHTML = text;
//     this.conversationTarget.appendChidl(preElement);
//   }
//
//   #setupEventSource() {
//     this.eventsource = new EventSource('/chat_responses?prompt=${this.promptTarget.value}')
//     this.eventsource.addEventListener("message", this.#handleMessage.bind(this))
//     this.eventsource.addEventListener("error", this.#handleError.bind(this))
//   }
//
//   #handleMessage(event) {
//     const parseData = JSON.parse(event.data)
//     this.currentPre.innerHTML += parseData.message;
//
//     this.conversationTarget.scrollTop = this.conversationTarget.scrollHeight;
//   }
//
//   #handleError(event) {
//     if (event.eventPhase === EventSource.CLOSED) {
//       this.eventSource.close()
//     }
//   }
//
//   disconnect() {
//     if (this.eventSource) {
//       this.eventsource.close()
//     }
//   }
// }